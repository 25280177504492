import React from 'react';
import { GoogleTagManager } from '@next/third-parties/google';
import useI18n from 'helpers/hooks/useI18n';
import {
  GTM_CONTAINER_ID_EN_CA,
  GTM_CONTAINER_ID_EN_US,
  GTM_CONTAINER_ID_ES_419,
  GTM_CONTAINER_ID_ES_MX,
  GTM_CONTAINER_ID_FR_CA,
  GTM_CONTAINER_ID_PR_BR,
} from '../../../helpers/constants/googleTagManager';

const GTMSnippet = () => {
  const { locale } = useI18n();
  const gtmMap = {
    'en-US': GTM_CONTAINER_ID_EN_US,
    'en-CA': GTM_CONTAINER_ID_EN_CA,
    'fr-CA': GTM_CONTAINER_ID_FR_CA,
    'es-MX': GTM_CONTAINER_ID_ES_MX,
    'es-419': GTM_CONTAINER_ID_ES_419,
    'pt-BR': GTM_CONTAINER_ID_PR_BR,
  };
  // @ts-ignore
  const GTM_CONTAINER_ID = gtmMap[locale || 'en-US'];

  if (Boolean(GTM_CONTAINER_ID)) {
    return <GoogleTagManager gtmId={GTM_CONTAINER_ID} />;
  } else {
    return <></>;
  }
};

export default GTMSnippet;
