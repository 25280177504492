import React, { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query';
import ContentstackLivePreview from '@contentstack/live-preview-utils';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { BREAD_SCRIPT_URL } from 'helpers/constants/bread';
import useDayjsLocale from 'helpers/hooks/useDayjsLocale';
import { useFormat } from 'helpers/hooks/useFormat';
import { getDomainName } from 'helpers/utils/getDomainName';
import { setMaxAge } from 'helpers/utils/setMaxAge';
import { getLocalizationInfo } from 'project.config';
import { sdk } from 'sdk';
import { createClient, ResponseError } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import GTMSnippet from '../components/headless/GTMSnippet';
import { PriceSpiderHead } from '../components/headless/PriceSpiderSnippet';
import TurnToSnippet from '../components/headless/TurnToSnippet';
import VWOSnippet from '../components/headless/VwoSnippet';
import { Log } from '../helpers/errorLogger';

ContentstackLivePreview.init();

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data, locale }: SlugProps) {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const productSkuId = router.asPath?.split('/p/')[1]?.split('/')?.[1] || '';
  useDayjsLocale();

  // remove locale from showing in url
  const sanitizeLocalizedUrl = () => {
    const url = window.location;
    let newPathname = `${url.pathname}${url.hash || ''}`;
    enum LOCALES {
      EN_US = 'en-us',
      EN_CA = 'en-ca',
      FR_CA = 'fr-ca',
      ES_MX = 'es-mx',
      ES_419 = 'es-419',
      PT_BR = 'pt-br',
    }

    if (
      url.pathname.toLowerCase().startsWith(`/redirect/${LOCALES.EN_US}`) ||
      url.pathname.toLowerCase().startsWith(`/redirect/${LOCALES.EN_CA}`) ||
      url.pathname.toLowerCase().startsWith(`/redirect/${LOCALES.FR_CA}`) ||
      url.pathname.toLowerCase().startsWith(`/redirect/${LOCALES.ES_MX}`) ||
      url.pathname.toLowerCase().startsWith(`/redirect/${LOCALES.ES_419}`) ||
      url.pathname.toLowerCase().startsWith(`/redirect/${LOCALES.PT_BR}`)
    ) {
      newPathname = url.pathname.replace(/\/[^/]+\/[^/]+\//, '/');
    } else if (
      url.pathname.toLowerCase().startsWith(`/${LOCALES.EN_US}`) ||
      url.pathname.toLowerCase().startsWith(`/${LOCALES.EN_CA}`) ||
      url.pathname.toLowerCase().startsWith(`/${LOCALES.FR_CA}`) ||
      url.pathname.toLowerCase().startsWith(`/${LOCALES.ES_MX}`) ||
      url.pathname.toLowerCase().startsWith(`/${LOCALES.ES_419}`) ||
      url.pathname.toLowerCase().startsWith(`/${LOCALES.PT_BR}`) ||
      url.pathname.toLowerCase().startsWith(`/redirect`)
    ) {
      newPathname = url.pathname.replace(/\/[^/]+\//, '/');
    }

    // remove 'slug' and all duplicates from url params
    const searchParams = new URLSearchParams(Object.fromEntries(new URLSearchParams(url.search)));
    searchParams.delete('slug');
    const updatedSearch = searchParams.toString() === '' ? '' : `?${searchParams.toString()}`;
    return (newPathname += updatedSearch);
  };

  if (typeof window !== 'undefined') {
    const newPathname = sanitizeLocalizedUrl();
    history.replaceState({ ...window.history.state, as: newPathname, url: newPathname }, '', newPathname);
  }

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts?.pop()?.split(';')?.shift();
    }
  };

  // add extension runner id for use by sposto in free entry code
  useEffect(() => {
    const timer = setInterval(() => {
      const cookieValue = getCookie('frontastic-session');
      if (Boolean(cookieValue)) {
        sessionStorage.setItem('bread-key', btoa(`${process.env.NEXT_PUBLIC_BREAD_INTEGRATION_KEY}`));
        sessionStorage.setItem('bread-url', btoa(`${process.env.NEXT_PUBLIC_BREAD_SCRIPT_URL}`));
        sessionStorage.setItem('frontastic-extension', btoa(`${process.env.NEXT_PUBLIC_EXT_BUILD_ID}`));
        sessionStorage.setItem('frontastic-host', btoa(`${process.env.NEXT_PUBLIC_FRONTASTIC_HOST}`));
        sessionStorage.setItem('frontastic-locale', btoa(`${getLocalizationInfo(locale)?.locale || 'en_US@USD'}`));
        sessionStorage.setItem('frontastic-session', cookieValue || '');
        clearInterval(timer);
        document.dispatchEvent(new CustomEvent('frontasticApiReady'));
      }
    }, 100);
  }, [locale]);

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data?.ok && data?.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  const domain = router.locale ? getDomainName(router.locale) : '';
  const cleanedPath = router.asPath.split('?')[0].split('#')[0];
  const canonicalUrl = domain ? `${domain}${cleanedPath}` : null;

  return (
    <>
      <Head>
        <title>
          {formatMessage({
            id: 'meta.title',
            defaultMessage: 'Cameras and photography equipment from Nikon',
          })}
        </title>
        <meta
          name="description"
          content={formatMessage({
            id: 'meta.desc',
            defaultMessage: 'Find high quality cameras and photography accessories at Nikon',
          })}
        />
        {<VWOSnippet locale={router.locale} />}

        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

        {productSkuId !== '' ? <PriceSpiderHead /> : ''}
      </Head>
      {productSkuId !== '' ? <TurnToSnippet sku={productSkuId} /> : ''}
      <GTMSnippet />
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />

      <Script src={BREAD_SCRIPT_URL} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({
  params,
  locale,
  query,
  req,
  res,
  resolvedUrl,
}) => {
  const maxAge = setMaxAge(req.url || '/');
  res.setHeader('Cache-Control', `max-age=${maxAge}`);

  sdk.configureForNext(locale as string);
  const frontastic = createClient();

  // if LOCAL or DEV environment, use "DEVELOPMENT ONLY" api hub credentials
  // QA will use the DEVELOPMENT api hub, but not the "DEVELOPMENT ONLY" credentials
  // UAT will use the STAGING api hub (staging api hub will ignore the "DEVELOPMENT ONLY" credentials
  // PROD will use the PRODUCTION api hub (staging api hub will ignore the "DEVELOPMENT ONLY" credentials
  req.headers['api-env'] =
    req.headers.host?.startsWith('localhost') ||
    req.headers.host?.startsWith('local.nikondev.com') ||
    req.headers.host?.startsWith('main--nikon') ||
    req.headers.host?.startsWith('dev--nikon') ||
    req.headers.host?.startsWith('nikonstore-dev') ||
    req.headers.host?.startsWith('dev.nikon') ||
    req.headers.host?.startsWith('dev-en-nikonca') ||
    req.headers.host?.startsWith('dev.en.nikon') ||
    req.headers.host?.startsWith('dev-fr-nikonca') ||
    req.headers.host?.startsWith('dev.fr.nikon')
      ? 'development'
      : '';

  const [data, categories] = await Promise.all([
    frontastic.getRouteData(params?.slug as string[], query as AcceptedQueryTypes, req, res),
    frontastic.getCategories(req, res),
  ]);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data && 'statusCode' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error(new Error('Error retrieving data: '), data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  const protocol = req.headers.referer?.split('://')[0] || 'https';

  const serverUrl = `${protocol}://${req.headers.host}${resolvedUrl}`;

  const serverState = null;

  return {
    props: {
      data: { ...data, categories, serverUrl, serverState } || null,
      locale: locale,
      ...(await serverSideTranslations(locale as string, [
        'account',
        'cart',
        'categories',
        'checkout',
        'common',
        'customer-support',
        'error',
        'learn-and-explore',
        'newsletter',
        'orders',
        'payment',
        'plp',
        'press-room-list-page',
        'product',
        'service-advisory-list',
        'search',
        'success',
        'thank-you',
        'wishlist',
        'narrow',
      ])),
    },
  };
};
